<template>
  <div>
    <div class="loader">
      <img src="../../assets/images/home-logo.gif" class="img-fluid" />
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  mounted() {
    setTimeout(() => {
        this.$router.push('/login');
    }, 5000);
  },
};
</script>


<style scoped>
.loader {
  background: #000;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>