import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/auth/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
    meta: { authOnly: false }
  },
  
  {
    path: "/login",
    name: "login",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/auth/LoginView.vue"
      ),

      meta: { authOnly: false }
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/auth/RegisterView.vue"
      ),

      meta: { authOnly: false }
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: () =>
      import(
        /* webpackChunkName: "forgotpassword" */ "../views/auth/ForgotPassword.vue"
      ),
      meta: { authOnly: false }
  },
  {
    path: "/otp",
    name: "otp",
    component: () =>
      import(/* webpackChunkName: "otp" */ "../views/auth/OTPVerification.vue"),
      meta: { authOnly: false }
  },
  
  {
    path: "/resetpassword",
    name: "resetpassword",
    component: () =>
      import(
        /* webpackChunkName: "resetpassword" */ "../views/auth/ResetPassword.vue"
      ),
      meta: { authOnly: false }
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/DashboardView.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/positions",
    name: "positions",
    component: () =>
      import(/* webpackChunkName: "positions" */ "../views/PositionsView.vue"),
      meta: { authOnly: true }
  },

  {
    path: "/rank",
    name: "rank",
    component: () =>
      import(/* webpackChunkName: "chart" */ "../views/RankView.vue"),
      meta: { authOnly: true }
  },

  {
    path: "/wallet",
    name: "wallet",
    component: () =>
      import(/* webpackChunkName: "wallet" */ "../views/WalletView.vue"),
      meta: { authOnly: true }
  },

  {
    path: "/setting",
    name: "setting",
    component: () =>
      import(/* webpackChunkName: "setting" */ "../views/SettingView.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/principlewallet",
    name: "principlewallet",
    component: () =>
      import(
        /* webpackChunkName: "principlewallet" */ "../views/wallet/PrincipleWallet.vue"
      ),
      meta: { authOnly: true }
  },
  {
    path: "/withdrawwallet",
    name: "withdrawwallet",
    component: () =>
      import(
        /* webpackChunkName: "withdrawwallet" */ "../views/wallet/WithdrawWallet.vue"
      ),
      meta: { authOnly: true }
  },
  {
    path: "/depositwallet",
    name: "depositwallet",
    component: () =>
      import(
        /* webpackChunkName: "depositwallet" */ "../views/wallet/DepositWallet.vue"
      ),
      meta: { authOnly: true }
  },
  {
    path: "/equalitywallet",
    name: "equalitywallet",
    component: () =>
      import(
        /* webpackChunkName: "equalitywallet" */ "../views/wallet/EqualityWallet.vue"
      ),
      meta: { authOnly: true }
  },

  
  {
    path: "/withdraw_history",
    name: "WithdrawHistory",
    component: () =>
      import(
        /* webpackChunkName: "WithdrawHistory" */ "../views/wallet/WithdrawHistory.vue"
      ),
      meta: { authOnly: true }
  },

  {
    path: "/today_income_report",
    name: "TodayIncomeReport",
    component: () =>
      import(
        /* webpackChunkName: "TodayIncomeReport" */ "../views/wallet/TodayIncomeReport.vue"
      ),
      meta: { authOnly: true }
  },


  {
    path: "/deposit",
    name: "deposit",
    component: () =>
      import(/* webpackChunkName: "deposit" */ "../views/DepositView.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () =>
      import(/* webpackChunkName: "withdraw" */ "../views/WithdrawView.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/activate",
    name: "activate",
    component: () =>
      import(/* webpackChunkName: "activate" */ "../views/ActivateView.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/terminate",
    name: "terminate",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/TerminateView.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/termcondition",
    name: "termcondition",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/TermCondition.vue"),
      meta: { authOnly: false }
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/AboutusView.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/notification",
    name: "notification",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/NotificationView.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/fund-transfer",
    name: "fundTransfer",
    component: () =>
      import(/* webpackChunkName: "withdraw" */ "../views/FundTransferView.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/transfer-history",
    name: "transferHistory",
    component: () =>
      import(/* webpackChunkName: "withdraw" */ "../views/TransferHistoryView.vue"),
      meta: { authOnly: true }
  },
  
  {
    path: "/rixos-pay",
    name: "rixosPay",
    component: () =>
      import(/* webpackChunkName: "withdraw" */ "../views/RixosPayView.vue"),
      meta: { authOnly: true }
  },

  {
    path: "/card-history",
    name: "cardHistory",
    component: () =>
      import(/* webpackChunkName: "withdraw" */ "../views/CardHistoryView.vue"),
      meta: { authOnly: true }
  },

  {
    path: "/changepassword",
    name: "changepassword",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/ChangePassword.vue"),
      meta: { authOnly: true }
  },

  {
    path: "/security",
    name: "security",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/SecurityView.vue"),
      meta: { authOnly: true }
  },

  //
  {
    path: "/support",
    name: "support",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/SupportView.vue"),
      meta: { authOnly: true }
  },
  
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/ProfileView.vue"),
      meta: { authOnly: true }
  },

  {
    path: "/members/:member?",
    name: "members",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/MembersView.vue"),
      meta: { authOnly: true }
  },

  {
    path: "/not-found",
    name: "BlankView",
    component: () =>
      import(  "../views/BlankView.vue"),
      meta: { authOnly: false }
  }


];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");
  const isAuth = to.matched.some((record) => record.meta.authOnly);
  
  if(to.fullPath == '/termcondition' || to.fullPath == '/not-found'){
    return next();
  }
   

  if (isAuth && !loggedIn) {
    return next({ path: "/" });
  } else if (!isAuth && loggedIn) {
    return next({ path: "/dashboard" });
  } else if (!isAuth && !loggedIn) {
    return next();
  }
  next();
});

export default router;
